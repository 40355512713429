<template>
  <v-scale-transition
    mode="out-in"
  >
    <v-row
      v-if="isAuthenticated"
      no-gutters
      class="flex-column"
    >
      <pop-up-window
        v-if="creditDetailsPopUpIsOpen"
      >
        <credit-details
          :credit="activeCredit"
          @onPopUpClose="togglePopUp('creditDetails', false)"
        />
      </pop-up-window>
      <pop-up-window
        v-if="loanPaymentPopUpIsOpen"
      >
        <loan-payment
          :cards="clientCards"
          :is-full-payment-of-the-loan="isFullPaymentOfTheLoan"
          :credit-id="currentCreditInfo.credit_id"
          :loan-debt="+currentCreditInfo.loan_balance"
          :min-credit-payment-value="+currentCreditInfo.min_monthly_payment "
          :loader-is-displaying="minCreditPaymentValueIsLoading"
          @onPopUpClose="togglePopUp('loanPayment', false)"
        />
      </pop-up-window>
      <notification
        v-if="errorNotification"
        type="info"
      >
        Нажаль ми не змогли знайти це посилання, спробуйте ще раз.
      </notification>
      <orders :order="activeOrder" />
      <credits
        v-if="credits.length > 0"
        :credits="credits"
        :is-down-loading-credits="isDownLoadingCredits"
        :un-success-loading="unSuccessLoading"
        @onCreditSelect="writeDownActiveCredit"
        @writeDownTypeOfPayment="writeDownTypeOfPayment"
      />
    </v-row>
  </v-scale-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import workWithProductsMixin from '@/mixins/getProductByCode';
import pageLoaderMixin from '../../mixins/pageLoaderMixin';
import Orders from './auxiliary-items/Orders';
import CreditDetails from './pop-up-windows/CreditDetails';
import PopUpWindow from '../../components/common/PopUpWindow';
import LoanPayment from './pop-up-windows/LoanPayment';
import OrderStatusService from '../../services/order-status-service';
import Credits from './auxiliary-items/Credits';
import notification from '../../components/common/Notification';
import errorNotificationMixin from '../../mixins/errorNotificationMixin';

export default {
  name: 'UserCredits',
  title: 'Мої кредити',
  components: {
    LoanPayment,
    CreditDetails,
    Orders,
    Credits,
    notification,
    PopUpWindow,
  },
  mixins: [workWithProductsMixin, pageLoaderMixin, errorNotificationMixin],
  data() {
    return {
      activeOrder: {},
      activeCredit: {},
      creditDetailsPopUpIsOpen: false,
      headersForCashOnline: {
        dataConfirmation: 'Підтвердіть, будь ласка, Ваші дані. ',
        additionalView: 'Заявку направлено на додатковий розгляд, очікуйте дзвінок оператора',
        agreed: 'Вітаємо, суму кредиту погоджено!',
        signed: ' Договір підписано! Очікуйте надходження коштів на картку',
        continuationOfRegistration: 'Продовжіть оформлення заявки',
        processing: 'Ваша заявка в обробці',
      },
      minCreditPaymentValueIsLoading: false,
      minCreditPaymentValue: 0,
      isFullPaymentOfTheLoan: false,
      loanPaymentPopUpIsOpen: false,
      currentCreditInfo: {},
      isDownLoadingCredits: true,
      unSuccessLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapGetters('clientInfo', [
      'activeCredits',
    ]),
    ...mapGetters('cashForm', [
      'isIdCard',
      'docTypeId',
    ]),
    ...mapGetters('userMessages', [
      'requestedMessage',
      'showNewMessagesHandler',
    ]),
    hasActiveOrder() {
      return this.$store.state.cashForm.hasActiveOrder;
    },
    credits: {
      get() {
        return this.$store.state.clientInfo.clientData.credits;
      },
      set(value) {
        this.$store.commit('clientInfo/UPDATE_FORM_DATA', {
          parentObjName: 'clientData',
          attribute: 'credits',
          value,
        });
      },
    },
    firstName() {
      return this.$store.state.clientInfo.clientData.firstName;
    },
    clientCards: {
      get() {
        return this.$store.state.bankCards.clientCards;
      },
      set(value) {
        this.$store.commit('bankCards/UPDATE_STATE', {
          key: 'clientCards',
          value,
        });
      },
    },
    products() {
      return this.$store.state.products;
    },
  },
  beforeMount() {
    this.getStateStore();
    this.getActiveOrderStore()
      .then((orders) => {
        if (orders) {
          // eslint-disable-next-line prefer-destructuring
          this.activeOrder = orders[0];
          this.activeOrder.title = this.getOrderTitle(this.activeOrder.action_type);
        }
        this.hidePagePreloader();
      }).catch((err) => console.error(err));
    this.isDownLoadingCredits = true;
    this.getCreditsStore()
      .then(() => {
        if (this.credits.length > 0) {
          this.isDownLoadingCredits = false;
          this.getProductsStore();
        }
      })
      .catch(() => {
        this.unSuccessLoading = true;
      });
    if (this.$route.query.inv !== undefined) {
      const { inv } = this.$route.query;
      this.sendInvitationQueryData(inv).then((data) => {
        if (data.success === true) {
          this.$router.push({ path: `systemMessages?message=${data.payload.object_id}` });
        } else {
          this.errorNotificationToogle();
        }
      }).catch(() => {
        this.errorNotificationToogle();
      });
    }
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.isAuthenticated) {
        this.switchBinotel();
        clearInterval(interval);
      }
    }, 500);
  },
  beforeDestroy() {
    // this.credits = [];
    this.switchBinotel(false);
  },
  methods: {
    ...mapActions('userMessages', [
      'sendInvitationQueryData',
      'requestForExactMessage',
      'switchNewMessageNotification',
    ]),
    ...mapActions('cashForm', [
      'getActiveOrderStore',
      'getStateStore',
    ]),
    ...mapActions('clientInfo', [
      'getCreditsStore',
      'getClientMainInfo',
      'getMinMonthlyPaymentStore',
    ]),
    ...mapActions('bankCards', [
      'getCardsStore',
    ]),
    ...mapActions(['getProductsStore']),
    switchBinotel(bool = true) {
      const classNameToShowBinotel = 'visible';
      const element = document.querySelector('#bingc-phone-button');
      if (bool) {
        // eslint-disable-next-line no-unused-expressions
        element ? element.classList.add(classNameToShowBinotel) : null;
      } else {
        // eslint-disable-next-line no-unused-expressions
        element ? element.classList.remove(classNameToShowBinotel) : null;
      }
    },
    togglePopUp(popUpName, bool = true) {
      this[`${popUpName}PopUpIsOpen`] = bool;
    },
    writeDownActiveCredit(activeCredit = {}) {
      this.activeCredit = activeCredit;
      this.togglePopUp('creditDetails');
    },
    getOrderTitle(orderActionType) {
      const actionType = orderActionType ? orderActionType.toLowerCase() : orderActionType;
      let title = '';
      switch (actionType) {
        case OrderStatusService.signature:
          title = this.headersForCashOnline.agreed;
          break;
        case OrderStatusService.identification:
          title = this.headersForCashOnline.dataConfirmation;
          break;
        case OrderStatusService.processing:
          title = this.headersForCashOnline.processing;
          break;
        default: title = this.headersForCashOnline.continuationOfRegistration;
          break;
      }

      return title;
    },
    writeDownTypeOfPayment({
      isFullPaymentOfTheLoan, currentCreditInfo,
    }) {
      this.isFullPaymentOfTheLoan = isFullPaymentOfTheLoan;
      this.currentCreditInfo = currentCreditInfo;
      if (!isFullPaymentOfTheLoan) {
        this.togglePopUp('loanPayment');
        this.minCreditPaymentValueIsLoading = true;
        this.getMinMonthlyPaymentStore(currentCreditInfo.creditId)
          // eslint-disable-next-line no-return-assign
          .then((data) => {
            this.minCreditPaymentValueIsLoading = false;
            if (data) {
              this.minCreditPaymentValue = data;
            }
          })
          .catch((error) => console.error(error));
      } else {
        this.togglePopUp('loanPayment');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
