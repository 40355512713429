<template>
  <v-row
    no-gutters
    class="flex-column"
  >
    <tab-header
      v-if="activeCredits.length !== 0"
      class="mb-2"
      icon-name="face-woman-outline"
      text="Мої діючі кредити"
    />

    <v-card
      v-for="(item, index) of activeCredits"
      :key="index"
      :class="['border-radius-10 box-shadow-none', { 'mt-7': index > 0 }]"
    >
      <v-card-text class="d-flex flex-column flex-md-row border-red pb-5">
        <v-col v-if="isDownLoadingCredits">
          <block-preloader
            :un-success="unSuccessLoading"
          />
        </v-col>
        <v-col
          v-else
          class="pa-0 mx-auto main-info mr-md-2"
        >
          <v-subheader
            class="text--text pa-0 height-auto d-flex flex-column align-center
             align-md-start "
          >
            <div class="circular">
              <div>
                <p class="text-h6 mb-0 error--text font-weight-medium">
                  {{ products[item.product] || item.product }}
                </p>
                <p class="mb-0 body-1 mt-2 mb-3">
                  <span class="font-weight-medium">Сума кредиту:</span>
                  {{ item.credit_value | sumFormatter }}₴
                </p>
                <!-- <p class="mb-0 body-1 mb-3">
                  <span class="font-weight-medium wordBrake">Залишок по кредиту:</span>
                  <template v-if="item.remains_to_pay">
                    {{ item.remains_to_pay | sumFormatter }}₴
                  </template>
                  <template v-else>
                    Немає;)
                  </template>
                </p> -->
                <p class="mb-0 body-1 mb-3">
                  <span class="font-weight-medium">Сума дострокового погашення кредиту: </span>
                  <template v-if="item.loan_balance">
                   <span style="word-break: keep-all">{{ item.loan_balance | sumFormatter }}₴
                   </span>
                  </template>
                  <template v-else>
                    Немає;)
                  </template>
                </p>
              </div>
              <v-col class="circularContainer secondBg box-shadow text--text" style="display: none">
                <p v-if="item.paid_total > 0" class="circularTitle textBold">
                  Погашено <br/> {{ item.paid_total | sumFormatter }}₴
                </p>
                <p v-else class="circularTitle textBold">
                  Погашено <br/> 0₴
                </p>
                <v-progress-circular
                  :rotate="180"
                  :size="100"
                  :width="15"
                  :value="item.paid_percent"
                  style="margin: 0 auto"
                  color="error"
                >
                  {{ item.paid_percent }}%
                </v-progress-circular>
                <span class="circularTitle textBold">
                  з {{ item.credit_value | sumFormatter }}₴
                </span>
              </v-col>
            </div>
          </v-subheader>

          <v-row
            no-gutters
            class="justify-space-between mt-5"
          >
            <v-btn
              small
              outlined
              color="error"
              class="col-12 col-sm-auto px-3"
              style="margin: 0 auto"
              @click="onCreditSelect(item)"
            >
              Стан кредиту
            </v-btn>
          </v-row>

          <p class="caption mb-0 text--text text-center mt-2">
            Ви можете повністю погасити кредит сплативши залишок та відсотки на поточну дату
          </p>
          <p class="caption mb-0 text--text text-center mt-2">
            Дата закінчення кредиту: <strong>{{ dateParser(item.credit_end) }}</strong>
          </p>
        </v-col>
        <v-col
          v-if="!isDownLoadingCredits"
          class="pa-0 d-flex justify-end mt-7 mt-md-0 "
        >
          <div
            class="border-radius-10 secondBg col-12 col-md--9 box-shadow text--text d-flex
                   flex-column align-center py-4 pt--5"
            style="justify-content: space-between"
          >
            <p class="textBold mb-0">Mінімальний платіж: </p>
            <p class="text-h5 text-sm-h4 text--text font-weight-medium mb-0 mt-0">
              <template v-if="item.min_monthly_payment > 0">
                {{ item.min_monthly_payment | sumFormatter }} ₴
              </template>
              <template v-else>
                <span style="color:#12b422">Внесено ;)</span>
              </template>
            </p>
            <p class="mb-0" v-if="item.min_monthly_payment > 0 && item.overdue_total >= 1">
              <strong>в тому числі прострочка {{ item.overdue_total | sumFormatter }} ₴</strong>
            </p>
            <div class="buttonsContainer">
              <v-btn
                v-if="item.min_monthly_payment > 0"
                middle
                rounded
                color="error"
                class="mt-3 mt-sm-0"
                @click="writeDownTypeOfPayment({
                  isFullPaymentOfTheLoan: false,
                  currentCreditInfo:item,
                })"
              >
                Внести платіж
              </v-btn>
              <v-btn
                middle
                color="error"
                outlined
                rounded
                class=" mt-3 mt-sm-0 px-3"
                @click="writeDownTypeOfPayment({
                  isFullPaymentOfTheLoan: true,
                  currentCreditInfo:item,
                })"
              >
                Повне погашення
              </v-btn>
            </div>
            <p
              class="caption mb-0 text-center mt-2"
            >
              <template v-if="item.next_payment_day !== '' ">
                Даний платіж необхідно внести до
                <!--{{ getDateBeforePayment(item.daysBeforePayment) | formatDate }}-->
                <span v-if="item.next_payment_day">{{ item.next_payment_day }}</span>
                <b v-else>00.00.20..</b>
                <strong v-if="item.days_before_payment != null && item.days_before_payment >= 0">
                  <br/>
                  <v-icon  dense>
                  mdi-calendar-clock
                </v-icon>
                  <span v-if="item.days_before_payment > 0" style="color: #646363">
                    через
               </span>
                  <span v-if="item.days_before_payment > 0" style="color: #646363">
                    {{ item.days_before_payment }}
               </span>
                  <span style="color: #646363">{{ endingDate(item.days_before_payment) }}</span>
                </strong>
              </template>
            </p>
          </div>
        </v-col>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import BlockPreloader from '../../../components/common/BlockPreloader';
import formatDateMixin from '../../../mixins/formatDateMixin';

export default {
  name: 'Credits',
  components: { BlockPreloader, TabHeader },
  mixins: [formatDateMixin],
  filters: {
    formatDate: (d) => d.toLocaleString('ru-RU')
      .replace(',', '')
      .slice(0, -3),
  },
  props: {
    credits: Array,
    isDownLoadingCredits: Boolean,
    unSuccessLoading: Boolean,
  },
  data() {
    return {
      activeCredits: [],
    };
  },
  computed: {
    products: {
      get() {
        return this.$store.state.products;
      },
    },
  },
  beforeMount() {
    this.activeCredits = this.credits.filter((el) => el.is_active === true);
  },
  methods: {
    endingDate(numberOfDays) {
      if (numberOfDays === 0) {
        return 'сьогодні';
      }
      if ([1, 21, 31].includes(numberOfDays)) {
        return 'день';
      }
      if ([2, 3, 4, 22, 23, 24].includes(numberOfDays)) {
        return 'дні';
      }
      return 'днів';
    },
    onCreditSelect(credit) {
      this.$emit('onCreditSelect', credit);
    },
    writeDownTypeOfPayment({ isFullPaymentOfTheLoan, currentCreditInfo }) {
      this.$emit('writeDownTypeOfPayment', {
        isFullPaymentOfTheLoan,
        currentCreditInfo,
      });
    },
    getDateBeforePayment(days) {
      const d = new Date();
      d.setDate(d.getDate() + days);
      return d;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 1rem 1rem 1rem 1rem;
  align-self: flex-end;
}
.main-info {
  @media screen and (min-width: 600px) and (max-width: 960px) {
    width: 360px;
  }
}

.circular {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.linear {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.circularContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  text-align: center;

  .circularTitle {
    font-size: 14px;
    margin-top: 10px;
  }
}

.linearContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  .linearTitle {
    align-self: flex-end;
    margin-top: 10px;
  }

  .bottomNumbers {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.textBold {
  font-size: 1rem !important;
  font-weight: 500;
  letter-spacing: 0.03125em !important;
  line-height: 1.5rem;
  font-family: "Roboto", sans-serif !important;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.wordBrake {
  word-break: keep-all;
}

@media (max-width: 460px) {
  .buttonsContainer {
    flex-direction: column;
    justify-content: center;
  }
  .circular {
    flex-direction: column;
  }
  .btn_pay {
    margin-right: 0;
  }
}
</style>
