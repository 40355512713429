<template>
  <div class="wrapper border-radius-10">
    <template v-if="!paymentsPopUpAreOpen">
      <v-card-title class="border-top-inherit d-flex justify-end success darken-1 py-2 pr-2 pl-4">
        <v-icon
          class="close-btn white--text"
          @click="onPopUpClose"
        >
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text
        class="px-0 pb-0 list-wrapper custom-scroll border-bottom-inherit position-relative">
        <block-preloader
          v-if="!attachedFilesAreUploaded || blockPreloaderIsVisible"
          background-color="background"
          class="border-radius-10"
        />

        <v-list color="secondBg border-bottom-inherit text--text">
          <v-list-item
            class=""
          >
            <v-list-item-content>
              Тип кредиту
            </v-list-item-content>
            <v-list-item-content
              class="justify-end"
            >
              {{ products[activeCredit.product] || activeCredit.product }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              Номер договору
            </v-list-item-content>
            <v-list-item-content
              class="justify-end"
            >
              {{ activeCredit.credit_id }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              Дата видачі
            </v-list-item-content>
            <v-list-item-content
              class="justify-end"
            >
              {{ activeCredit.credit_start | formatDate }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              Сума
            </v-list-item-content>
            <v-list-item-content
              class="justify-end"
            >
              {{ activeCredit.credit_value | sumFormatter }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              Залишок на сьогодні
            </v-list-item-content>
            <v-list-item-content
              class="justify-end"
            >
              {{ activeCredit.remains_to_pay | sumFormatter }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content style="color: darkorange; text-align: center">
              Увага! Клієнте, інформація щодо заборгованості
              по Вашому кредиту оновлюється раз на добу.
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              class="font-weight-medium cursor-pointer hover-link body-2 d-flex justify-center"
              @click="togglePaymentsPopUp(true)"
            >
              Платежі по кредиту
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="getCreditContract(activeCredit.order_id)"
            class="font-weight-medium cursor-pointer hover-link body-2"
          >
            <a
              :href="getCreditContract(activeCredit.order_id).viewUrl"
              target="_blank"
              class="hover-link text--text text-decoration-none"
            >
              {{ getCreditContract(activeCredit.order_id).name }}
            </a>
          </v-list-item>
        </v-list>
      </v-card-text>
    </template>
    <template v-else>
      <v-card-title
        class="border-top-inherit d-flex justify-space-between success darken-1 py-2 pr-2 pl-4"
      >
        <v-icon
          class="cursor-pointer white--text"
          @click="togglePaymentsPopUp(false)"
        >
          mdi-keyboard-backspace
        </v-icon>

        <v-icon
          class="cursor-pointer white--text"
          @click="onPopUpClose"
        >
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="overflow-x-hidden  px-0 pb-0 list-wrapper border-bottom-inherit
       position-relative">
        <block-preloader
          v-if="paymentsAreLoading"
          background-color="background"
        />

        <v-list color="secondBg border-bottom-inherit">
          <v-list-item
            class=""
          >
            <v-list-item-content class="body-1">
              Дата платежу
            </v-list-item-content>
            <v-list-item-content
              class="justify-end body-1"
            >
              Сума платежу
            </v-list-item-content>
          </v-list-item>
          <div
            v-if="paymentsForOneLoan.length > 0"
          >
            <v-list-item
              v-for="(item, index) of paymentsForOneLoan"
              :key="index"
            >
              <v-list-item-content>
                {{ item.created_at | formatDate }}
              </v-list-item-content>
              <v-list-item-content
                class="justify-end"
              >
                {{ item.payment_sum }}
              </v-list-item-content>
            </v-list-item>
          </div>
          <p
            v-else
            class="px-4"
          >
            Ви ще не робили жодного платежу
          </p>
        </v-list>
      </v-card-text>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BlockPreloader from '@/components/common/BlockPreloader';
import blockPreloaderMixin from '../../../mixins/blockPreloaderMixin';

export default {
  name: 'CreditDetails',
  components: { BlockPreloader },
  filters: {
    formatDate: (d) => {
      // eslint-disable-next-line no-param-reassign
      d = new Date(d);
      return d.toLocaleString('ru-RU').replace(',', '').slice(0, -8);
    },
  },
  mixins: [blockPreloaderMixin],
  props: {
    credit: {
      type: Object,
    },
  },
  data() {
    return {
      paymentsAreLoading: false,
      activeCredit: {},
      paymentsPopUpAreOpen: false,
      attachedFilesAreUploaded: false,
    };
  },
  computed: {
    ...mapGetters([
      'getCreditContract',
    ]),
    paymentsForOneLoan: {
      get() {
        return this.$store.state.clientInfo.clientData.payments_for_one_loan;
      },
      set(value) {
        this.$store.commit('clientInfo/UPDATE_FORM_DATA', {
          parentObjName: 'clientData',
          attribute: 'paymentsForOneLoan',
          value,
        });
      },
    },
    products: {
      get() {
        return this.$store.state.products;
      },
    },
  },
  mounted() {
    this.activeCredit = this.credit;
    this.getAttachedFilesStore(this.activeCredit.orderId)
      .then(() => {
        this.attachedFilesAreUploaded = true;
      });
  },
  beforeDestroy() {
    this.activeCredit = {};
    this.paymentsForOneLoan = [];
  },
  methods: {
    ...mapActions([
      'getAttachedFilesStore',
    ]),
    ...mapActions('clientInfo', [
      'getPaymentHistoryStore',
    ]),
    togglePaymentsPopUp(bool) {
      this.paymentsPopUpAreOpen = bool;

      if (bool) {
        this.paymentsAreLoading = true;
        this.getPaymentHistoryStore(this.activeCredit.credit_id)
          // eslint-disable-next-line no-return-assign
          .then(() => this.paymentsAreLoading = false)
          .catch((err) => console.log(err));
      }
    },
    onPopUpClose() {
      this.togglePaymentsPopUp(false);
      this.$emit('onPopUpClose');
    },
  },
};
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 90vw;

    @media screen and (min-width: 600px) {
      width: 400px;
    }
  }

  .list-wrapper {
    max-height: 80vh;
    overflow-y: auto;
  }

  .border-top-inherit {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .border-bottom-inherit {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
</style>
