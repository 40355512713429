<template>
  <v-row
    no-gutters
    class="flex-column"
  >
    <tab-header
      icon-name="check-decagram-outline"
      :text="hasAnyOrder ? 'Доступно для оформлення' : 'Оформлення кредиту'"
      class="mb-1"
    />
    <pop-up-window v-if="showNotification">
      <CustomNotification
        title="Послуга тимчасово недоступна :( "
        inner-text=""
      />
    </pop-up-window>
    <template
      v-if="hasAnyOrder"
    >
      <v-card class="border-radius-10 box-shadow-none">
        <v-card-text
          class="d-flex pt-4 pb-5 pt-md-3 pb-md-10 border-green flex-column flex-sm-row box-shadow"
        >
          <v-col class="pa-0 d-flex flex-column align-center titleSt">
            <v-subheader
              v-if="isCashOnlineOrder"
              class="subtitle-1 text-md-h6 text--text pa-0 text-center height-auto mb-3"
            >
              {{ order.title }}
            </v-subheader>
            <v-subheader
              v-else
              class="subtitle-1 text-md-h6 text--text pa-0 text-center height-auto"
            >
              <template v-if="orderHasActionType">
                {{ order.title }}
              </template>
              <template v-else-if="!orderHasActionType">
                {{ titles.processing }}
              </template>
            </v-subheader>
            <div class="text-h4 text-sm-h3 white--text text-center font-weight-medium col-12 pa-0">
              <p class="mb-0 mx-auto success col-12 pa-0 border-radius-10 py-2 col-md-10">
                {{ order.credit_value | sumFormatter }}₴
              </p>
            </div>
          </v-col>
          <v-col class="pa-0 d-flex flex-column-reverse flex-sm-column justify-sm-end align-center">
            <v-btn
              v-if="!dontShowButton"
              rounded
              color="success"
              @click="goTo"
            >
              <template v-if="orderHasActionType">
                {{ buttonTitles[order.action_type.toLowerCase()] }}
              </template>
              <template v-else>
                {{ buttonTitles.continuationOfRegistration }}
              </template>
            </v-btn>

            <p
              v-if="isCashOnlineOrder"
              class="ma-0 mt-2 mb-2 mb-sm-0 text--text caption text-md-subtitle-1
               text-center isCashOnlineOrder"
            >
              <template v-if="orderHasActionType">
                {{ titles[order.action_type.toLowerCase()] }}
              </template>
              <template v-else-if="!orderHasActionType">
                {{ titles.continuationOfRegistration }}
              </template>
            </p>
            <!--            <p-->
            <!--              v-else-->
            <!--              class="ma-0 mt-2 mb-2 mb-sm-0 text&#45;&#45;
text caption text-md-subtitle-1 text-center"-->
            <!--            >-->
            <!--              <template v-if="orderHasActionType">-->
            <!--                {{ titles[order.action_type.toLowerCase()] }}-->
            <!--              </template>-->
            <!--              <template v-else-if="!orderHasActionType">-->
            <!--                {{ titles.processing }}-->
            <!--              </template>-->
            <!--            </p>-->
          </v-col>
        </v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-card class="border-radius-10 box-shadow-none">
        <v-card-text
          class="d-flex border-green box-shadow justify-center align-center py-12"
        >
          <v-btn
            class="success"
            rounded
            @click="cashOnCardBtnHandler"
          >
            Кредит на картку
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TabHeader from '@/components/common/TabHeader';
import RouteNamesService from '@/services/route-names-service';
import OrderStatusService from '../../../services/order-status-service';
import CustomNotification from '../../../components/common/CustomNotification';
import PopUpWindow from '../../../components/common/PopUpWindow';

export default {
  name: 'AvailableForRegistration',
  components: { TabHeader, CustomNotification, PopUpWindow },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    order: Object,
  },
  data() {
    return {
      dataCl: {},
      buttonTitles: {
        signature: 'Отримати',
        continuationOfRegistration: 'Продовжити',
        identification: 'Підтвердити',
        fill_order: 'Продовжити',
      },
      cashFormRouteName: RouteNamesService.cashForm,
      currentTitle: '',
      paths: {
        cashForm: RouteNamesService.cashForm,
        contractSigning: RouteNamesService.contractSigning,
        identification: RouteNamesService.userIdentification,
        userIdentificationById: RouteNamesService.userIdentificationById,
      },
      productCodes: {
        cashOnline: 'cache_online',
        commodityCredit: 'Commodity Credit',
      },
      titles: {
        signature: 'Підпишіть договір для отримання коштів',
        continuationOfRegistration: 'Натисніть "Продовжити", щоб продовжити оформлення заявки',
        identification: 'Підтвердіть, будь ласка, Ваші дані.',
        processing: 'Ваша заявка в обробці',
      },
      showNotification: false,
    };
  },
  computed: {
    clientId: {
      get() {
        return this.$store.state.clientInfo.clientData.client_id;
      },
    },
    hasAnyOrder() {
      // eslint-disable-next-line no-restricted-syntax
      for (const i in this.order) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.order.hasOwnProperty(i)) {
          return true;
        }
      }
      return false;
    },
    orderHasActionType() {
      return this.order.action_type;
    },
    isCashOnlineOrder() {
      return this.order.product === this.productCodes.cashOnline;
    },
    dontShowButton() {
      if (this.orderHasActionType) {
        return this.order.action_type.toLowerCase() === OrderStatusService.processing;
      }
      return !this.isCashOnlineOrder && !this.orderHasActionType;
    },
    ...mapGetters('auth', [
      'isNewCreditAvailable',
    ]),
  },
  beforeMount() {
    this.getStateStore().then((res) => {
      if (res === false) {
        this.getLastStateStore(this.clientId);
      }
    });
    this.isStaffToogle();
  },
  methods: {
    ...mapActions('auth', [
      'isStaffToogle',
      'addOrderAttempt',
    ]),
    ...mapActions('cashForm', [
      'getStateStore',
      'getLastStateStore',
    ]),
    cashOnCardBtnHandler() {
      window.open('https://online.sfactor.com.ua/join?ref=mysf');
      // if (this.isNewCreditAvailable === true) {
      //   this.$router.push({
      //     name: this.paths.cashForm,
      //   });
      // } else {
      //   this.showNotification = true;
      //   this.addOrderAttempt();
      //   const showTimeout = setTimeout(() => {
      //     this.showNotification = false;
      //     clearTimeout(showTimeout);
      //   }, 3000);
      // }
    },
    goTo() {
      if (this.orderHasActionType) {
        switch (this.order.action_type.toLowerCase()) {
          case OrderStatusService.signature:
            this.$router.push({
              name: RouteNamesService.contractSigning,
              params: { currentOrderId: this.order.order_id },
              query: { signature: this.order.signature },
            });
            break;
          case OrderStatusService.identification:
            this.$router.push({
              name: this.paths.identification,
              params: { currentOrderId: this.order.order_id },
            });
            break;
          default:
            this.$router.push({ name: this.paths.cashForm });
            (this.isNewCreditAvailable ? '' : this.addOrderAttempt)();
            break;
        }
      } else {
        this.$router.push({ name: this.paths.cashForm });
      }
    },
  },
};
</script>

<style scoped>
.isCashOnlineOrder{
  display: block;
}
@media (max-width: 600px) {
  .isCashOnlineOrder{
    display: none;
  }
.titleSt{
  margin-bottom: 20px;
}
}
</style>
